.mark {
  background-color: yellow;
}
.highlighted {
  background-color: orange; /* For navigation highlighting */
}
.highlight {
  background-color: yellow;
  font-weight: bold;
}

::selection {
  background-color: yellow !important; /* Change selection color */
  color: black !important;
}
